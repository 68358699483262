import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/LoverV2MultiplePage/Info'
import Science from '../components/LoverV2MultiplePage/Science'
import HowItWorks from '../components/LoverV2MultiplePage/HowItWorks'
import Benefits from '../components/LoverV2MultiplePage/Benefits'
import Price from '../components/LoverV2MultiplePage/Price'
import Reviews from '../components/LoverV2MultiplePage/Reviews'
import Faq from '../components/LoverV2MultiplePage/Faq'
import References from '../components/LoverV2MultiplePage/References'
import Wim from '../components/LoverV2MultiplePage/Wim'

export default ({ pageContext: { data } }) => {
  return (
    <Layout data={data} signUpUrl={data.info?.buttonUrl} signUpText={data.info?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {data?.faq?.items && <FaqSchema questions={data.faq.items} />}
      <Info info={data.info} />
      <Science science={data.science} table={data.table} />
      <HowItWorks howItWorks={data.howItWorks} availabilityText={data.info?.availabilityText} />
      <Benefits benefits={data.benefits} />
      <Price price={data.price} />
      <Reviews reviews={data.reviews} />
      <Faq faq={data.faq} />
      <References references={data.references} />
      <Wim wim={data.wim} />
    </Layout>
  )
}
