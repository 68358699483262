import React, { useState } from 'react'
import cx from 'classnames'
import styles from './style.module.sass'

export default ({ reviews }) => {
  if (reviews.isHide) return null

  const [open, setOpen] = useState(false)
  const connectedList = reviews.reviewList.title ? true : false
  const title = connectedList ? reviews.reviewList.title : reviews.title
  const count = connectedList ? reviews.reviewList.reviewsCount : reviews.reviewsCount
  const order = connectedList ? reviews.reviewList.reviewsCount : reviews.reviewsOrder
  const items = connectedList ? reviews.reviewList.items : reviews.items

  return (
    <div className={styles.reviews}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{title}</h2>

        <div className={styles.content}>
          <div className={styles.info}>
            <p className={styles.infoCount}>{count}</p>
            <p className={styles.infoOrder}>{order}</p>
          </div>

          <div className={styles.list}>
            {items &&
              items.map((item, index) => (
                <div
                  className={cx(styles.item, {
                    [styles.itemHidden]: index > 2 && !open,
                  })}
                  key={item._key}
                >
                  <div className={styles.left}>
                    <p className={styles.name}>{item.name}</p>
                  </div>
                  <div className={styles.middle}>
                    <p className={styles.header}>{item.title}</p>
                    <p className={styles.protocol} dangerouslySetInnerHTML={{ __html: item.protocol }} />
                  </div>
                  <div className={styles.right}>
                    <p className={styles.date}>{item.date}</p>
                  </div>
                </div>
              ))}
          </div>

          <div
            className={cx(styles.button, {
              [styles.buttonOpen]: open,
            })}
            onClick={() => setOpen(!open)}
          >
            {open ? 'Hide' : 'Show'} all
          </div>
        </div>
      </div>
    </div>
  )
}
